import { types } from "../../actions/types";

  const initialState = {
    message: null,
    error: null,
    loading: false,
    company: null,
    companies:null
  };
  
  export default function partnerCompanies(state = initialState, action) {
    switch (action.type) {
      case types.COMPANIES_PENDING:
        return {
          ...state,
          loading:true
        };
      case types.GET_COMPANIES_SUCCESS:
        return {
          ...state,
          loading:false,
          message: action.message,
          companies: action.companies
        };
      
        case types.REGISTER_COMPANY_SUCCESS:
          return {
            ...state,
            loading:false,
            message: action.message,
            user: action.data
          };
        case types.COMPANIES_ERROR:
          return {
            ...state,
            error: action.error,
            loading: false,
          };
      default:
        return state;
    }
  };