import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Generate Sales Data

const data = [
  {
    name: "January",
    successed: 4000,
    failed: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    successed: 3000,
    failed: 1398,
    amt: 2210,
  },
  {
    name: "March",
    successed: 2000,
    failed: 9800,
    amt: 2290,
  },
  {
    name: "April",
    successed: 2780,
    failed: 3908,
    amt: 2000,
  },
  {
    name: "June",
    successed: 1890,
    failed: 4800,
    amt: 2181,
  },
  {
    name: "July",
    successed: 2390,
    failed: 3800,
    amt: 2500,
  },
  {
    name: "August",
    successed: 3490,
    failed: 4300,
    amt: 2100,
  },
  {
    name: "September",
    successed: 3490,
    failed: 4300,
    amt: 2100,
  },
  {
    name: "October",
    successed: 3490,
    failed: 4300,
    amt: 2100,
  },
  {
    name: "November",
    successed: 3490,
    failed: 4300,
    amt: 2100,
  },
  {
    name: "December",
    successed: 3490,
    failed: 4300,
    amt: 2100,
  },
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "USSD Transactions",
    },
  },
};

export default function Chart() {
  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" />
          <Line
            type="monotone"
            dataKey="successed"
            stroke="#4c7d0e"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="failed" stroke="#c27a14" />
          <Line type="monotone" dataKey="amt" stroke="#c2bc14" />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
