import * as React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Title from "./Title";
const data = [
  {
    name: "2019",
    successed: 4000,
    failed: 2400,
    amt: 2400,
  },
  {
    name: "2020",
    successed: 3000,
    failed: 1398,
    amt: 2210,
  },
  {
    name: "2021",
    successed: 2000,
    failed: 9800,
    amt: 2290,
  },
  {
    name: "2022",
    successed: 2780,
    failed: 3908,
    amt: 2000,
  },
  {
    name: "2023",
    successed: 1890,
    failed: 4800,
    amt: 2181,
  },
];

export default function Transaction({ title, item }) {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <div className="bg-gray-200 rounded-sm p-4 w-full">
        <p className="text-sm">
          Successed: <span>{500}</span>
        </p>
        <p className="text-sm">
          Failed: <span>{500}</span>
        </p>
        <p className="text-sm">
          Amount: <span>{500}</span>
        </p>
      </div>
      <div className="h-[7rem] w-full mt-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            // margin={{
            //   top: 20,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis hide />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="successed" stackId="a" fill="#8884d8" />
            <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
}
