import React, { useState, useEffect } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Datepicker from "react-tailwindcss-datepicker";
import "../styles/NewForm.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getPromotions,
  savePromotion,
} from "../redux/actions/transactions/transactions";

const AddPromotion = ({ open, handleClose, openSnack }) => {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [promos, setPromos] = useState([{ from: "", to: "", value: "" }]);
  const dispatch = useDispatch();

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const { promotionLoading, savePromotionSuccess } = useSelector(
    (state) => state.momoTransactions
  );
  const removePromos = (index) => {
    setPromos((state) => state.filter((_item, idx) => idx !== index));
  };

  const addPromos = () => {
    setPromos((state) => [...state, { from: "", to: "", value: "" }]);
  };

  useEffect(() => {
    if (savePromotionSuccess) {
      setValue({ startDate: null, endDate: null });
      setPromos([{ from: "", to: "", value: "" }]);
      openSnack();
      dispatch(getPromotions());
      handleClose();
    }
  }, [promotionLoading, savePromotionSuccess]);

  const addPromoData = (e, index) => {
    const { name, value } = e.target;
    const newPromo = promos;
    const changes = { ...newPromo[index], [name]: value };
    newPromo[index] = changes;
    setPromos([...newPromo]);
  };

  const handleSavePromotion = async () => {
    const payload = {
      runningFrom: value.startDate,
      runningTo: value.endDate,
      promoRanges: promos,
    };
    console.log(JSON.stringify(payload, null, 2));
    dispatch(savePromotion(payload));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="paper">
          <h1 className="font-bold text-center">Add promotion</h1>
          <div className="my-4">
            <h3>Promotion duration</h3>
            <Datepicker
              value={value}
              onChange={handleValueChange}
              showShortcuts={true}
              inputClassName="w-full rounded-md focus:ring-0 font-normal bg-[#fff] placeholder:text-[#666666] border-[1px] border-[#c4c4c4] focus:border-black p-[0.4rem]"
            />
          </div>
          <div className="my-4">
            <h3 className="my-4">Promotion parcentages</h3>
            {promos.map((_item, idx) => (
              <div key={idx} className="flex mb-4">
                <TextField
                  fullWidth
                  size="small"
                  id="percentages"
                  label="Minumum amount in Rwf"
                  name="from"
                  value={promos[idx].from}
                  onChange={(e) => addPromoData(e, idx)}
                  type="number"
                  InputProps={{ inputProps: { min: 100, max: 75000 } }}
                />
                <TextField
                  fullWidth
                  size="small"
                  id="percentages"
                  label="Maximum amount in Rwf"
                  name="to"
                  value={promos[idx].to}
                  type="number"
                  InputProps={{ inputProps: { min: 100, max: 75000 } }}
                  onChange={(e) => addPromoData(e, idx)}
                  sx={{
                    mx: 2,
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  name="value"
                  id="percentages"
                  label="Promotion in percentages"
                  value={promos[idx].value}
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  type="number"
                  onChange={(e) => addPromoData(e, idx)}
                />
                {promos.length > 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => removePromos(idx)}
                  >
                    <RemoveCircleIcon fontSize="small" color="warning" />
                  </IconButton>
                )}
              </div>
            ))}
            <IconButton aria-label="delete" onClick={addPromos}>
              <AddCircleIcon fontSize="large" color="primary" />
            </IconButton>
          </div>
          <div className="flex justify-between my-6">
            <Button
              size="small"
              className="cancel-buttons"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className="create-button"
              variant="contained"
              onClick={handleSavePromotion}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddPromotion;
