import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import { getWifiData } from "../../redux/actions/wifi-data/wifi-data";
import { getTodayISO } from "../../utils/getISOToday";

const WifiData = () => {
  const [dateValue, setDateValue] = useState();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.wifiData);
  console.log(getTodayISO());

  useEffect(() => {
    dispatch(
      getWifiData(
        dateValue || { startDate: getTodayISO(), endDate: getTodayISO() }
      )
    );
  }, [dispatch, dateValue]);
  let rows = [];

  if (state && state.wifiData) {
    rows = state.wifiData;
  }

  const columns = [
    "No",
    "Company",
    "Bus",
    "Online hours",
    "Offline hours",
    "SIM Card",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <CustomTable
            rows={rows}
            columns={columns}
            tableTitle="Wifi data"
            tableType="wifi"
            dateValue={dateValue}
            setDateValue={setDateValue}
            useMultipleDates
          />
        </>
      )}
    </div>
  );
};

export default WifiData;
