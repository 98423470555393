import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { getAllCardsProfile } from "../../redux/actions/cards/cards";
import CustomTable from "../../components/CustomTable";
import TablePaginationActions from "../../components/TablePagination";

const Profile = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 24 });
  const state = useSelector((state) => state.cards);
  useEffect(() => {
    dispatch(getAllCardsProfile(pagination));
  }, [dispatch, pagination]);
  let rows = [];

  if (state && state.profiles?.length) {
    rows = state.profiles;
  }

  const handleChangePage = (_event, newPage) => {
    // console.log({ event, newPage });
    setPagination((state) => ({ ...state, page: newPage }));
  };
  const columns = [
    "No",
    "Registered Date",
    "First Name",
    "Last Name",
    "National Id",
    "gender",
    "Country",
    "Province",
    "District",
    "Sector",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <CustomTable
            rows={rows}
            columns={columns}
            tableType="cards-profiles"
            tableTitle="Cards Profiles"
            pageSize={pagination.pageSize}
            count={state.profileCount}
            paginationActions={() =>
              TablePaginationActions({
                page: pagination.page,
                rowsPerPage: pagination.pageSize,
                onPageChange: handleChangePage,
                count: state.profileCount,
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default Profile;
