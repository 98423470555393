import React, { useEffect } from "react";
import { Modal, Backdrop, Fade } from "@mui/material";
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import "../styles/NewForm.scss";
// import swal from "sweetalert";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "../redux/actions/companies/companies";
import { adminCreateUser } from "../redux/actions/auth/auth";
import { nanoid } from "nanoid";

const NewUserForm = ({ open, handleClose }) => {
  const dispacth = useDispatch();
  // Get the companies list
  const companiesList = useSelector(
    (state) => state.partnerCompanies.companies
  );
  const userValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email can't be empty")
      .email("Your email address is invalid"),
    firstName: Yup.string().required("First name is required"),
    username: Yup.string().required("Username is required"),
    orgId: Yup.string().required("No company was selected."),
  });

  const handleSubmit = (value) => {
    let rand_passowrd = nanoid();
    value.password = rand_passowrd;
    value.confirmPassword = rand_passowrd;
    dispacth(adminCreateUser(value));
  };

  useEffect(() => {
    dispacth(getCompanies());
  }, [dispacth]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="paper">
          <h2>Add new user</h2>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              orgId: null,
            }}
            validationSchema={userValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Box component="form" onSubmit={formProps.handleSubmit}>
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    id="fullName"
                    className="input-style"
                    label="First Name"
                    name="firstName"
                    value={formProps.values.firstName}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.touched.firstName && formProps.errors.firstName
                        ? true
                        : false
                    }
                    helperText={
                      formProps.touched.firstName && formProps.errors.firstName
                    }
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="fullName"
                    className="input-style"
                    label="Last Name"
                    name="lastName"
                    value={formProps.values.lastName}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.touched.lastName && formProps.errors.lastName
                        ? true
                        : false
                    }
                    helperText={
                      formProps.touched.lastName && formProps.errors.lastName
                    }
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    id="username"
                    className="input-style"
                    label="Username"
                    name="username"
                    value={formProps.values.username}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.touched.username && formProps.errors.username
                        ? true
                        : false
                    }
                    helperText={
                      formProps.touched.username && formProps.errors.username
                    }
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    className="input-style"
                    label="Personal Email"
                    name="email"
                    value={formProps.values.email}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                      formProps.touched.email && formProps.errors.email
                        ? true
                        : false
                    }
                    helperText={
                      formProps.touched.email && formProps.errors.email
                    }
                  />
                  <FormControl
                    fullWidth
                    margin="normal"
                    className="input-style"
                    error={
                      formProps.touched.orgId && formProps.errors.orgId
                        ? true
                        : false
                    }
                  >
                    <InputLabel id="companyName-label">Company Name</InputLabel>
                    <Select
                      labelId="companyName-label"
                      name="orgId"
                      id="orgId"
                      label="Company Name"
                      value={formProps.values.orgId}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                    >
                      {companiesList?.map((list) => (
                        <MenuItem
                          key={list.name}
                          value={list.id}
                          data-cy={`option-${list.Id}`}
                        >
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formProps.touched.orgId && formProps.errors.orgId}
                    </FormHelperText>
                  </FormControl>
                </>
                <br />
                <div className="model_btn_wrap">
                  <Button
                    type="submit"
                    divider
                    className="create-button"
                    variant="contained"
                    size="small"
                  >
                    Create
                  </Button>
                  <Button
                    type="button"
                    divider
                    className="cancel-buttons"
                    variant="contained"
                    size="small"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </Box>
            )}
          </Formik>
        </div>
      </Fade>
    </Modal>
  );
};

export default NewUserForm;
