import http from "../../../utils/axios/axios";
import { types } from "../types";

export const getPermissions = () => (dispatch) => {
  dispatch({ type: types.PERMISSIONS_DATA_PENDING });
  return http
    .post("/api/permission/all-permissions")
    .then((res) => {
      dispatch({
        type: types.GET_PERMISSIONS_DATA_SUCCESS,
        message: res.data.message,
        permissions: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.PERMISSIONS_DATA_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
