import http from "../../../utils/axios/axios";
import { types } from "../types";

export const login = (data, navigate) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });
  return http
    .post("/api/users/login", data, { withCredentials: true })
    .then((res) => {
      navigate("/dashboard");
      dispatch({
        type: types.LOGIN_SUCCESS,
        message: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const signup = (data, switchToSignIn) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });
  return http
    .post("/api/users", data)
    .then((res) => {
      switchToSignIn();
      dispatch({
        type: types.REGISTER_SUCCESS,
        message: res.data.message,
        data: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
export const logout = (navigate) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });
  return http
    .post("/api/users/logout")
    .then((res) => {
      navigate("/");
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: types.USERS_DATA_PENDING });
  return http
    .post("/api/users")
    .then((res) => {
      dispatch({
        type: types.GET_USERS_DATA_SUCCESS,
        message: res.data.message,
        users: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.USERS_DATA_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};

export const adminCreateUser = (data) => (dispatch) => {
  dispatch({ type: types.AUTH_PENDING });
  return http
    .post("/api/users/signup", data)
    .then((res) => {
      dispatch({
        type: types.REGISTER_SUCCESS,
        message: res.data.message,
        data: res.data.data,
      });
      dispatch(getUsers());
    })
    .catch((err) => {
      dispatch({
        type: types.AUTH_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
export const authUserData=()=>(dispatch)=>{
   dispatch({type:types.AUTH_USER_DATA_PENDING});
   return http
   .post("/api/users/authed",{})
   .then((res)=>{
    dispatch({
      type:types.GET_AUTH_USER_DATA_SUCCESS,
      message: res.data.message,
      data: res.data.data,
    });
   })
   .catch((err)=>{
    dispatch({
      type:types.AUTH_USER_DATA_ERROR,
      error: err.response ? err.response.data.error : "Error occured",
    });
   });
};
export const updateUserData=(user)=>(dispatch)=>{
  dispatch({type:types.USER_DATA_UPDATING});
  return http
  .post("/api/users/update-profile",user)
  .then((res)=>{
    dispatch({  type:types.USER_DATA_UPDATING_SUCCESS,
      message:res.data.message,
      data:res.data.data,
    });
  })
  .catch((err)=>{
    dispatch({
      type:types.USER_DATA_UPDATING_ERROR,
      error:err.response ? err.response.data.error : "Error occured"
    })
  })
};
