import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../Loader";
import { getCompanies } from "../../../redux/actions/companies/companies";
import CustomTable from "../../CustomTable";

const Companies = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.partnerCompanies);
  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  let rows = [];

  if (state && state.companies) {
    rows = state.companies;
  }
  const columns = ["No", "company", "Lead", "Email", "Status", "Actions"];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <CustomTable
            rows={rows}
            columns={columns}
            tableType="companies"
            tableTitle="All companies"
          />
        </>
      )}
    </div>
  );
};

export default Companies;
