import { Fragment } from "react";
import { useState,useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "../styles/nav.css";
import CustomLink from "./CustomLink";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth/auth";
import { useNavigate, useLocation } from "react-router-dom";
import ProfilePage from "../screens/profile/profilepage/ProfilePage";
import { useSelector} from "react-redux";
import { authUserData } from "../redux/actions/auth/auth";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const navItems = [
    {
      name: "Dashboard",
      path: "dashboard",
      current: location.pathname.includes("dashboard"),
    },
    {
      name: "MoMo",
      path: "momo",
      current: location.pathname.includes("momo"),
    },
    {
      name: "USSD",
      path: "ussd",
      current: location.pathname.includes("ussd" || "promotions"),
    },
    {
      name: "WiFi",
      path: "wifi-usage",
      current: location.pathname.includes("wifi"),
    },
    {
      // a page that we use to discribe what we do, list all partner companies, and add contact us page to allow new partners willing to work with AC Mobility Experss interest
      name: "About",
      path: "about",
      current: location.pathname.includes("about"),
    },
  ];
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const data= useSelector((state)=>state.auth.user)
  useEffect(()=>{

    dispatch(authUserData());
    console.log("loading")
  },[])
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-4 lg:px-6">
            <div className="relative flex h-16 items-center justify-around">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start custom-nav-pos">
                <div></div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navItems.map((item) => {
                      return (
                        <CustomLink
                          to={item.path}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </CustomLink>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={data?.image}
                        alt="Profile"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() =>
                                setProfileModalOpen(!isProfileModalOpen)
                              }
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="http://subsidy.tapandgoticketing.co.rw/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => dispatch(logout(navigate))}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block  px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navItems.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={"p"}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <CustomLink to={item.path}>{item.name}</CustomLink>
                  {/* {item.name} */}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>

          {/* Profile Modal Rendering*/}
          {isProfileModalOpen && (
            <ProfilePage onClose={() => setProfileModalOpen(false)} />
          )}
        </>
      )}
    </Disclosure>
  );
};


export default Navbar;
