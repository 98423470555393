import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fab);

const SocialIcons = () => {
  return (
    <div className="social-media">
      <a href="https:/facebook.com/" className="social-icon">
        <FontAwesomeIcon icon={["fab", "fa-facebook-f"]} />
      </a>
      <a href="https://twitter.com/" className="social-icon">
        <FontAwesomeIcon icon={["fab", "fa-twitter"]} />
      </a>
      <a href="https://google.com/" className="social-icon">
        <FontAwesomeIcon icon={["fab", "fa-google"]} />
      </a>
      <a href="https://linkedin.com/" className="social-icon">
        <FontAwesomeIcon icon={["fab", "fa-linkedin-in"]} />
      </a>
    </div>
  );
};

export default SocialIcons;
