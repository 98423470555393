import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  loading: false,
  user: null,
  users: null,
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        user: action.data,
      };
    case types.AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.AUTH_USER_DATA_PENDING:
      return{
        ...state,
        loading:true,
      };
    case types.GET_AUTH_USER_DATA_SUCCESS:
      return{
        ...state,
        loading:false,
        message:action.message,
        user: action.data,
      };
    case types.AUTH_USER_DATA_ERROR:
      return{
        ...state,
        error:action.error,
        loading:false,
      };      
      case types.USER_DATA_UPDATING:
        return{
          ...state,
          loading:true,
        };
      case types.USER_DATA_UPDATING_SUCCESS:
        return{
          ...state,
          loading:false,
          message:action.message,
          // user:action.data,
        };
      case types.USER_DATA_UPDATING_ERROR:
        return{
          ...state,
          error:action.error,
          loading:false,
        };    
    default:
      return state;
  }
}
