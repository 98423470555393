import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { getAllCards } from "../../redux/actions/cards/cards";
import CustomTable from "../../components/CustomTable";
import TablePaginationActions from "../../components/TablePagination";

const Cards = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 24 });
  const state = useSelector((state) => state.cards);
  useEffect(() => {
    dispatch(getAllCards(pagination));
  }, [dispatch, pagination]);
  let rows = [];

  const handleChangePage = (_event, newPage) => {
    // console.log({ event, newPage });
    setPagination((state) => ({ ...state, page: newPage }));
  };

  if (state && state.cards.length) {
    rows = state.cards;
  }
  const columns = [
    "No",
    "Registered Date",
    "CardNumber",
    "PhoneNumber",
    "National Id",
    "Balance",
    "Actions",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <CustomTable
            rows={rows}
            columns={columns}
            tableType="cards"
            tableTitle="All Cards"
            pageSize={pagination.pageSize}
            count={state.cardCount}
            paginationActions={() =>
              TablePaginationActions({
                page: pagination.page,
                rowsPerPage: pagination.pageSize,
                onPageChange: handleChangePage,
                count: state.cardCount,
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default Cards;
