export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "rwf",
});

export const dateFormatter = (date) => {
  const actualDate = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(actualDate);
};
