import http from "../../../utils/axios/axios";
import { types } from "../types";

export const getCompanies = () => (dispatch) => {
  dispatch({ type: types.COMPANIES_PENDING });
  return http
    .post("/api/companies/list")
    .then((res) => {
      dispatch({
        type: types.GET_COMPANIES_SUCCESS,
        message: res.data.message,
        companies: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.COMPANIES_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
