import { types } from "../types";

export const getOperators = () => async (dispatch) => {
	dispatch({ type: types.GET_OPERATORS_PENDING });
	try {
		const data = await fetch(
			`${process.env.REACT_APP_KIGALI_TOPUP_URL}/operators/corporate-operators`,
			{
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({ corporateId: 2 }),
			}
		);
		const res = await data.json();
		dispatch({
			type: types.GET_OPERATORS_SUCCESS,
			message: res.message,
			data: res.data,
		});
	} catch (err) {
		dispatch({
			type: types.GET_OPERATORS_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const createOperator = (operator) => async (dispatch) => {
	dispatch({ type: types.CREATE_OPERATOR_PENDING });
	try {
		const data = await fetch(
			`${process.env.REACT_APP_KIGALI_TOPUP_URL}/operators/create`,
			{
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({ ...operator, corporateId: 2 }),
			}
		);
		const res = await data.json();
		dispatch({
			type: types.CREATE_OPERATOR_SUCCESS,
			message: res.message,
			data: res.data,
		});
	} catch (err) {
		dispatch({
			type: types.CREATE_OPERATOR_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const provideFloat = (operatorId, float) => async (dispatch) => {
	dispatch({ type: types.PROVIDE_FLOAT_PENDING });
	try {
		const data = await fetch(
			`${process.env.REACT_APP_KIGALI_TOPUP_URL}/operators/provideFloat`,
			{
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({ operatorId, float }),
			}
		);
		const res = await data.json();
		dispatch({
			type: types.PROVIDE_FLOAT_SUCCESS,
			message: res.message,
			data: res.data,
		});
	} catch (err) {
		dispatch({
			type: types.PROVIDE_FLOAT_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};
