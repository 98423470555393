import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader";
import { getRoles } from "../../../redux/actions/roles/Roles";
import CustomTable from "../../CustomTable";

const Roles = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.roles);
  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);
  useEffect(() => {}, [state]);
  let rows = [];

  if (state && state.roles) {
    rows = state.roles;
  }
  const columns = ["No", "Role", "Status", "Description", "Actions"];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <CustomTable
            rows={rows}
            columns={columns}
            tableType="roles"
            tableTitle="All roles"
          />
        </>
      )}
    </div>
  );
};

export default Roles;
