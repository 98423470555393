export const getTodayISO = () => {
  const today = new Date();

  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  const stringFiedDate = todayDate.toString();
  const stringfiedMonth = String(todayMonth + 1);

  const formatedDate =
    stringFiedDate.length < 2 ? `0${stringFiedDate}` : stringFiedDate;
  const formatedMonth =
    stringfiedMonth.length < 2 ? `0${stringfiedMonth}` : stringfiedMonth;

  const isoDate = `${todayYear}-${formatedMonth}-${formatedDate}`;
  return isoDate;
};

/**
 * Get utc format of a day
 * @param {*} dateString
 * @param {*} end indicating the end of the day time
 * @returns
 */
export const getUtcDate = (dateString, end = false) => {
  const date = new Date(dateString);

  if (end) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
  }

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return date.toISOString();
};

/**
 * Get the start of the week
 * @returns
 */
export const getStartOfWeek = () => {
  const currentDate = new Date();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

  return startOfWeek;
};

/**
 * Get the start of the month
 * @returns
 */
export const getStartOfMonth = () => {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return startOfMonth;
};
