import React from 'react'
import '../styles/loader.scss'

const Loader = () => {
  return (
    <div className='loader-wrapper'>
        <div className="loading">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    </div>

  )
}

export default Loader