import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import { getCokTopupTransaction } from "../../redux/actions/transactions/transactions";
import { formatter } from "../../utils/currencyFormatter";

const CokTopupTransactions = () => {
	const dispatch = useDispatch();

	const [dateValue, setDateValue] = useState();
	const state = useSelector((state) => state.momoTransactions);

	useEffect(() => {
		dispatch(getCokTopupTransaction());
	}, [dispatch, dateValue]);
	let rows = [];
	let total = 0;
	if (state && state.cokTopupTransactions) {
		rows = state.cokTopupTransactions;
		total = state.cokTopupTransactions.reduce((prev, curr) => {
			return prev + Number(curr.amount);
		}, 0);
	}
	const columns = [
		"No",
		"Date",
		"Card number",
		"Topup amount",
		"Previous Amount",
	];

	return (
		<div>
			{state && state.loadingcokTopupTransaction ? (
				<>
					<Loader />
				</>
			) : (
				<>
					<CustomTable
						rows={rows}
						columns={columns}
						total={formatter.format(total)}
						tableType="cok"
						// setDateValue={setDateValue}
						// dateValue={dateValue}
					/>
				</>
			)}
		</div>
	);
};

export default CokTopupTransactions;
