import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import { formatter } from "../../utils/currencyFormatter";
import { getOperators } from "../../redux/actions/operators/operators";
import AddOperator from "../../components/AddOperator";
import { Alert, Snackbar } from "@mui/material";
import OperatorDetails from "../../components/operatorDetails";

const CokTopupTransactions = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [openDetails, setOpenDetails] = useState(false);
	const [details, setDetails] = useState(undefined);
	const [openSnack, setOpenSnack] = useState(false);
	const [dateValue, setDateValue] = useState();
	const { operators, loading } = useSelector((state) => state.operators);

	let rows = [];
	if (operators) {
		rows = operators;
	}
	const columns = ["No", "Username", "Email", "Float"];

	useEffect(() => {
		dispatch(getOperators());
	}, [dispatch, dateValue]);

	const handleOpenSnack = () => {
		setOpenSnack(true);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnack(false);
	};

	return (
		<div>
			{loading ? (
				<>
					<Loader />
				</>
			) : (
				operators && (
					<>
						<CustomTable
							rows={rows}
							columns={columns}
							tableTitle="Operators"
							tableType="cokOperators"
							action={() => setOpen(true)}
							viewMore={(id) => {
								setDetails(
									operators.filter((operator) => operator.id === id)[0]
								);
								setOpenDetails(true);
							}}
						/>
					</>
				)
			)}
			<AddOperator
				open={open}
				handleClose={() => setOpen(false)}
				openSnack={handleOpenSnack}
			/>
			<Snackbar
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%" }}
				>
					Operator added successfully
				</Alert>
			</Snackbar>
			{details && (
				<OperatorDetails
					open={openDetails}
					handleClose={() => setOpenDetails(false)}
					details={details}
					loading={loading}
				/>
			)}
		</div>
	);
};

export default CokTopupTransactions;
