import { types } from "../types";

export const getAllCards =
    (pagination) =>
        async (dispatch) => {
            dispatch({ type: types.GET_CARDS_PENDING });
            try {
                const data = await fetch(
                    `${process.env.REACT_APP_KYC_URL}/api/v1/cards/all?page=${pagination.page}&pageSize=${pagination.pageSize}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "POST",
                    }
                );
                const res = await data.json();
                dispatch({
                    type: types.GET_CARDS_SUCCESS,
                    message: res.message,
                    data: res.data.content,
                    count: res.data.count
                });
            } catch (err) {
                dispatch({
                    type: types.GET_CARDS_ERROR,
                    error: err.response ? err.response.data.error : "Error occured",
                });
            }
        };

export const getAllCardsProfile =
    (pagination) =>
        async (dispatch) => {
            dispatch({ type: types.GET_CARDS_PENDING });
            try {
                const data = await fetch(
                    `${process.env.REACT_APP_KYC_URL}/api/v1/cards/profiles?page=${pagination.page}&pageSize=${pagination.pageSize}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "POST",
                    }
                );
                const res = await data.json();
                console.log({ res })
                dispatch({
                    type: types.GET_PROFILE_SUCCESS,
                    message: res.message,
                    data: res.data.content,
                    count: res.data.count
                });
            } catch (err) {
                dispatch({
                    type: types.GET_CARDS_ERROR,
                    error: err.response ? err.response.data.error : "Error occured",
                });
            }
        };