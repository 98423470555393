import React from "react";
import { useSelector } from "react-redux";

import Navbar from "../../components/Navbar";
import Loader from "../../components/Loader";

const Admin = () => {
  const state = useSelector((state) => state.momoTransactions);

  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            <Navbar />
          </div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            <Navbar />
          </div>
          <p>This is an admin of the site</p>
        </>
      )}
    </div>
  );
};

export default Admin;
