import { types } from "../../actions/types";

const initialState = {
	message: null,
	createMessage: null,
	providedFloatMessage: null,
	error: null,
	createError: null,
	providedFloatError: null,
	loading: false,
	creatingOperator: false,
	providingFloat: false,
	operators: [],
};

export default function getRoles(state = initialState, action) {
	switch (action.type) {
		case types.GET_OPERATORS_PENDING:
			return {
				...state,
				loading: true,
			};
		case types.GET_OPERATORS_SUCCESS:
			return {
				...state,
				loading: false,
				message: action.message,
				operators: action.data,
			};
		case types.GET_OPERATORS_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			};

		// Create Operator
		case types.CREATE_OPERATOR_PENDING:
			return {
				...state,
				creatingOperator: true,
			};
		case types.CREATE_OPERATOR_SUCCESS:
			return {
				...state,
				creatingOperator: false,
				createMessage: action.message,
				operators: action.data,
			};
		case types.GET_OPERATORS_ERROR:
			return {
				...state,
				createError: action.error,
				creatingOperator: false,
			};

		// Provide Float
		case types.PROVIDE_FLOAT_PENDING:
			return {
				...state,
				providingFloat: true,
			};
		case types.PROVIDE_FLOAT_SUCCESS:
			return {
				...state,
				providingFloat: false,
				providedFloatMessage: action.message,
			};
		case types.PROVIDE_FLOAT_ERROR:
			return {
				...state,
				providedFloatError: action.error,
				providingFloat: false,
			};
		default:
			return state;
	}
}
