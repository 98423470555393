import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader";
import { getUsers } from "../../../redux/actions/auth/auth";
import CustomTable from "../../CustomTable";
import "../../../styles/common.css";
import NewUserForm from "../../NewUserForm";
const Users = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);
  const HandleDelete = function (value) {
    console.log("delete clicked", value);
  };
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  let rows = [];

  if (state && state.users) {
    rows = state.users;
  }
  const columns = [
    "No",
    "Names",
    "Phone",
    "Email",
    "Status",
    "Company",
    "Actions",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}>
            <button className="btn-common " onClick={() => setOpen(true)}>
              Add new user
            </button>
          </div>

          <CustomTable
            rows={rows}
            columns={columns}
            tableType="users"
            tableTitle="All users"
            HandleDelete={HandleDelete}
          />
        </>
      )}
      <NewUserForm open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

export default Users;
