import React, { useState } from "react";
import { Modal, Backdrop, Fade, Input } from "@mui/material";
import { Button } from "@mui/material";
import "../styles/NewForm.scss";
import {
	forceTransaction,
	getTransactions,
} from "../redux/actions/transactions/transactions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import {
	getOperators,
	provideFloat,
} from "../redux/actions/operators/operators";

const OperatorDetails = ({ open, handleClose, details, loading }) => {
	details["corporateId"] = undefined;
	details["password"] = undefined;
	details["updatedAt"] = undefined;
	details["createdAt"] = undefined;
	const [isProvidingFloat, setIsProvidingFloat] = useState(false);
	const [floatAmount, setFloatAmount] = useState(0);

	const dispatch = useDispatch();
	const { providingFloat, providedFloatMessage } = useSelector(
		(state) => state.operators
	);

	const handleProvideFloat = async () => {
		dispatch(provideFloat(details.id, floatAmount));
	};

	React.useEffect(() => {
		if (providedFloatMessage) {
			dispatch(getTransactions());
			setIsProvidingFloat(false);
			setFloatAmount(0);
			dispatch(getOperators());
			handleClose();
		}
	}, [dispatch, providingFloat, providedFloatMessage]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className="modal"
			open={open}
			onClose={handleClose}
			closeAfterTransition
		>
			<Fade in={open}>
				<div className="paper" style={{ width: "30%" }}>
					<h2>Transaction Details</h2>
					{loading ? (
						<Loader />
					) : (
						<>
							<div className="my-6">
								{Object.keys(details).map((item, idx) =>
									item === "float" ? (
										<div
											className={`flex my-2 ${
												isProvidingFloat ? "flex-col" : ""
											}`}
											key={idx}
										>
											<p className="font-bold text-xs">float:</p>
											{isProvidingFloat ? (
												<Input
													placeholder="How much?"
													aria-label="Float"
													type="number"
													onChange={(e) => setFloatAmount(e.target.value)}
												/>
											) : (
												<p className="ml-4 text-xs">{details[item]} Rwf</p>
											)}
										</div>
									) : details[item] ? (
										<div className="flex my-2" key={idx}>
											<p className="font-bold text-xs">
												{item.replace(/([a-z])([A-Z])/g, "$1 $2")}:
											</p>
											<p className="ml-4 text-xs">{details[item]}</p>
										</div>
									) : null
								)}
							</div>
							<div className="flex justify-center my-4">
								<Button
									size="small"
									className="create-button"
									variant="contained"
									onClick={() => {
										isProvidingFloat && floatAmount > 0
											? handleProvideFloat()
											: setIsProvidingFloat(true);
									}}
								>
									{providingFloat ? "Providing float" : "Provide Float"}
								</Button>
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

export default OperatorDetails;
