import * as React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import BusinessIcon from "@mui/icons-material/Business";
import WifiIcon from "@mui/icons-material/Wifi";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DialpadIcon from "@mui/icons-material/Dialpad";
import LanIcon from "@mui/icons-material/Lan";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import { GroupOutlined } from "@mui/icons-material";

export const list = [
	{
		name: "Dashboard",
		to: "",
		icon: <DashboardIcon />,
	},
	{
		name: "Users",
		to: "users",
		icon: <PeopleIcon />,
	},
	{
		name: "Companies",
		to: "companies",
		icon: <BusinessIcon />,
	},
	{
		name: "Roles",
		icon: <LanIcon />,
		sublist: [
			{
				name: "Roles",
				to: "users/roles",
				icon: <Diversity3Icon />,
			},
			{
				name: "Permissions",
				to: "users/permissions",
				icon: <WorkspacePremiumIcon />,
			},
		],
	},

	{
		name: "Transactions",
		icon: <ReceiptLongIcon />,
		sublist: [
			{
				name: "USSD",
				to: "ussd",
				icon: <DialpadIcon />,
			},
			{
				name: "MoMo",
				to: "momo",
				icon: <BookOnlineIcon />,
			},
			{
				name: "COK Topup",
				to: "cok-topup",
				icon: <BookOnlineIcon />,
			},
			{
				name: "COK Operators",
				to: "cok-operators",
				icon: <GroupOutlined />,
			},
		],
	},
	{
		name: "Cards",
		icon: <StyleOutlinedIcon />,
		sublist: [
			{
				name: "Profiles",
				to: "cards-profiles",
				icon: <RecentActorsOutlinedIcon />,
			},
			{
				name: "Cards",
				to: "cards",
				icon: <PaymentOutlinedIcon />,
			},
		],
	},
	{
		name: "WiFi data",
		to: "wifi-usage",
		icon: <WifiIcon />,
	},
	{
		name: "Reports",
		to: "reports",
		icon: <BarChartIcon />,
	},
];
