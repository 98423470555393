import React, { useState, useEffect } from "react";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../styles/auth/login.css";
import LoginImg from "./img/log.svg";
import RegisterImg from "./img/register.svg";
import SocialIcons from "../../components/SocialIcons";
import { login, signup } from "../../redux/actions/auth/auth";
import { getCompanies } from "../../redux/actions/companies/companies";
import LogoWhite from "../../assets/logo_blue.png";

library.add(fab, fas);

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const AuthError = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const [signupMode, setSignupMode] = useState(false);

  //login information
  const [logEmail, setLogEmail] = useState();
  const [logPswd, setLogPswd] = useState();

  //signup informaiton

  const [signupEmail, setSignupEmail] = useState();
  const [signupPswd, setSignupPswd] = useState();
  const [repeatedPswd, setRepeatedPswd] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [username, setUsername] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [orgId, setOrgId] = useState();
  const [, setOnError] = useState(null);

  const switchToSignIn = () => setSignupMode(false);

  //login handles
  const handleLogEmail = (e) => setLogEmail(e.target.value);
  const handleLogPassword = (e) => setLogPswd(e.target.value);

  //signup handlers

  const handleSignupEmail = (e) => setSignupEmail(e.target.value);
  const handleSignupPswd = (e) => setSignupPswd(e.target.value);
  const handleRepeatPswd = (e) => setRepeatedPswd(e.target.value);
  const handleFirstname = (e) => setFirstname(e.target.value);
  const handleLastname = (e) => setLastname(e.target.value);
  const handleUsername = (e) => setUsername(e.target.value);
  const handlePhone = (e) => setPhoneNumber(e.target.value);
  const handleCompany = (e) => setOrgId(e.target.value);

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      email: logEmail,
      password: logPswd,
    };
    dispatch(login(data, navigate));
  };

  const handleSignup = (e) => {
    e.preventDefault();
    const data = {
      email: signupEmail,
      password: signupPswd,
      firstname,
      lastname,
      phoneNumber,
      // image,
      orgId,
      username,
    };
    if (signupPswd === repeatedPswd) {
      dispatch(signup(data, switchToSignIn));
    }
  };
  useEffect(() => {
    setOnError(AuthError);
  }, [AuthError]);
  return (
    <div className={`container ${signupMode ? "sign-up-mode" : ""}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form">
            <h2 className="title">Sign in</h2>
            <span className="login-error-message">
              {AuthError?.error && "Incorrect username or password."}
            </span>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-envelope"]} />
              </i>
              <input
                type="email"
                placeholder="Email"
                onChange={handleLogEmail}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-lock"]} />
              </i>
              <input
                type="password"
                placeholder="Password"
                onChange={handleLogPassword}
              />
            </div>
            <button className="btn solid" onClick={handleLogin}>
              Login
            </button>
            <p className="social-text">Follow us on our social media.</p>
            <SocialIcons />
          </form>
          <form action="#" className="sign-up-form">
            <h2 className="title">Sign up</h2>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-user"]} />
              </i>
              <input
                type="text"
                placeholder="Firstname"
                onChange={handleFirstname}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-user"]} />
              </i>
              <input
                type="text"
                placeholder="Lastname"
                onChange={handleLastname}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-user"]} />
              </i>
              <input
                type="text"
                placeholder="Username"
                onChange={handleUsername}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-envelope"]} />
              </i>
              <input
                type="email"
                placeholder="Email"
                onChange={handleSignupEmail}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-phone"]} />
              </i>
              <input
                type="tel"
                placeholder="Phonenumber"
                onChange={handlePhone}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-user"]} />
              </i>
              <select required name="cars" id="cars" onChange={handleCompany}>
                <option value="">Company</option>
                {state &&
                  state.partnerCompanies &&
                  state.partnerCompanies.companies && (
                    <>
                      {state.partnerCompanies.companies.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </>
                  )}
              </select>
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-lock"]} />
              </i>
              <input
                type="password"
                placeholder="Password"
                onChange={handleSignupPswd}
              />
            </div>
            <div className="input-field">
              <i>
                <FontAwesomeIcon icon={["fas", "fa-lock"]} />
              </i>
              <input
                type="password"
                placeholder="Repeat Password"
                onChange={handleRepeatPswd}
              />
            </div>
            <button className="btn" onClick={handleSignup}>
              Sign up
            </button>
          </form>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={LogoWhite}
                alt="mobility logo"
                style={{
                  width: "150px",
                  height: "auto",
                }}
              />
            </div>
            <p>
              Welcome! We are happy to have you. You can use this platform to
              get live data about different projects that we run together.
            </p>
          </div>
          <img src={LoginImg} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={LogoWhite}
                alt="mobility logo"
                style={{ width: "150px", height: "auto" }}
              />
            </div>
            <p>
              Welcome back! Thank you for trusting AC Mobility. Use this
              platform to get live data about different projects that we run
              together. Click the button below to get started.
            </p>
            <button
              onClick={switchToSignIn}
              className={`btn transparent`}
              id="sign-in-btn"
            >
              Sign in
            </button>
          </div>
          <img src={RegisterImg} className="image" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
