import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import {
  activatePromotion,
  getPromotions,
} from "../../redux/actions/transactions/transactions";
import { formatter } from "../../utils/currencyFormatter";
import Navbar from "../../components/Navbar";
import AddPromotion from "../../components/AddPromotion";
import { Snackbar, Alert } from "@mui/material";

const Promotions = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [dateValue, setDateValue] = useState();
  const state = useSelector((state) => state.momoTransactions);
  const { ctivatePromotionLoading, activatePromotionSuccess } = useSelector(
    (state) => state.momoTransactions
  );

  let rows = [];
  useEffect(() => {
    dispatch(getPromotions());
  }, [dispatch]);

  useEffect(() => {
    if (activatePromotionSuccess) {
      dispatch(getPromotions());
    }
  }, [ctivatePromotionLoading, activatePromotionSuccess]);

  let total = 0;
  if (state && state.promotions) {
    // total = state.transactions.total;
    rows = state.promotions.sort((a, b) => Number(b.active) - Number(a.active));
  }

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleActivatePromotion = async (groupId) => {
    dispatch(activatePromotion({ groupId }));
  };

  const columns = [
    "No",
    "Starting from",
    "End date",
    "Min value in Rwf",
    "Max value in Rw",
    "Promotion value in %",
    "Status",
    "Action",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <CustomTable
            rows={rows}
            columns={columns}
            total={formatter.format(total)}
            tableType="promotions"
            tableTitle="Promotions"
            setDateValue={setDateValue}
            dateValue={dateValue}
            action={() => setOpen(true)}
            viewMore={handleActivatePromotion}
          />
        </>
      )}
      <AddPromotion
        open={open}
        handleClose={() => setOpen(false)}
        openSnack={handleOpenSnack}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
        >
          Promotion added successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Promotions;
