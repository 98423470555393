import http from "../../../utils/axios/axios";
import { types } from "../types";

export const getRoles = () => (dispatch) => {
  dispatch({ type: types.ROLES_DATA_PENDING });
  return http
    .post("/api/role/all-roles")
    .then((res) => {
      dispatch({
        type: types.GET_ROLES_DATA_SUCCESS,
        message: res.data.message,
        roles: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.ROLES_DATA_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
