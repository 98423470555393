import * as React from "react";
import Chart from "./Chart";
import Transactions from "./Transaction";
import Activities from "./Activities";
import WifiDataComponent from "./WifiData";
import { Grid, Paper } from "@mui/material";
import Title from "./Title";
export default function Overview() {
  return (
    <React.Fragment>
      <Grid container spacing={3} className="items-center">
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              height: 300,
            }}
          >
            <Title>Ussd Transactions</Title>
            <Chart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <Transactions title="Ussd Transactions" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              height: 300,
            }}
          >
            <Title>Momo Transactions</Title>
            <Chart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <Transactions title="Momo Transactions" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              height: 300,
            }}
          >
            <Title>Wifi Usage</Title>
            <WifiDataComponent />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <Activities />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
