import * as React from "react";
import Title from "./Title";

export default function Transaction() {
  return (
    <React.Fragment>
      <Title>Recent Activities</Title>
      <div className="overflow-y-auto">
        {[...new Array(12)].map((_item, idx) => (
          <div key={idx} className="flex my-2">
            <div className="h-2 w-2 rounded-[50%] bg-slate-300 mt-[0.2rem]"></div>
            <div className="ml-2">
              <p className="text-[0.5rem] text-slate-400">2 days ago</p>
              <p className="text-xs text-slate-500">
                John Doe added new user on parters portal
              </p>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
