import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { list } from "./listData";
import { useLocation } from "react-router-dom";

export const MainListItems = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(null);
  const [selected, setSelected] = React.useState();
  const location = useLocation();

  const handleOpentitles = (item) => {
    open === item ? setOpen(null) : setOpen(item);
  };

  React.useEffect(() => {
    setSelected(
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    );
  }, [location.pathname]);

  const handleSelection = (item) => {
    setSelected(item.name);
    navigate(item.to);
    console.log({ path: location.pathname.split("/") });
    console.log({ selected });
  };

  return (
    <React.Fragment>
      {list.map((item, idx) => {
        return item?.sublist ? (
          <>
            <ListItemButton onClick={() => handleOpentitles(item.name)}>
              <ListItemIcon
                sx={{
                  color: "#1F2A37",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
              {open === item.name ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open === item.name} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.sublist.map((list, index) => (
                  <ListItemButton
                    sx={
                      selected === list.name.toLowerCase()
                        ? {
                            bgcolor: "#f0f0f0",
                            borderRight: "medium solid #1F2A37",
                            pl: 6,
                          }
                        : {
                            pl: 6,
                          }
                    }
                    key={index}
                  >
                    <ListItemIcon
                      sx={{
                        color: "#1F2A37",
                      }}
                    >
                      {list.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={list.name}
                      onClick={() => handleSelection(list)}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItemButton
            sx={
              selected === item.name.toLowerCase() || selected === item.to
                ? {
                    bgcolor: "#f0f0f0",
                    borderRight: "medium solid #1F2A37",
                  }
                : null
            }
            key={idx}
          >
            <ListItemIcon
              sx={{
                color: "#1F2A37",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              onClick={() => handleSelection(item)}
            />
          </ListItemButton>
        );
      })}
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon
          sx={{
            color: "#1F2A37",
          }}
        />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon
        sx={{
          color: "#1F2A37",
        }}
      >
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon
          sx={{
            color: "#1F2A37",
          }}
        />
      </ListItemIcon>
      <ListItemText primary="Year-end transactions" />
    </ListItemButton>
  </React.Fragment>
);
