import http from "../../../utils/axios/axios";
import { types } from "../types";

export const getWifiData = (range) => (dispatch) => {
  dispatch({ type: types.WIFI_DATA_PENDING });
  return http
    .post("/api/wifi-data", range)
    .then((res) => {
      dispatch({
        type: types.GET_WIFI_DATA_SUCCESS,
        message: res.data.message,
        wifiData: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.WIFI_DATA_ERROR,
        error: err.response ? err.response.data.error : "Error occured",
      });
    });
};
