import React, { useState, useEffect } from "react";
import {
	Modal,
	Fade,
	TextField,
	Button,
	IconButton,
	Input,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Datepicker from "react-tailwindcss-datepicker";
import "../styles/NewForm.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	getPromotions,
	savePromotion,
} from "../redux/actions/transactions/transactions";
import {
	createOperator,
	getOperators,
} from "../redux/actions/operators/operators";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const AddOperator = ({ open, handleClose, openSnack }) => {
	const [operatorData, setOperatorData] = useState({
		username: "",
		email: "",
		password: "",
	});
	const [showPass, setShowPass] = useState(false);
	const dispatch = useDispatch();
	const handleOnChange = (e) => {
		e.preventDefault();
		setOperatorData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const { creatingOperator, createMessage } = useSelector(
		(state) => state.operators
	);

	useEffect(() => {
		if (createMessage) {
			setOperatorData({ username: "", email: "", password: "" });
			openSnack();
			dispatch(getOperators());
			handleClose();
		}
	}, [creatingOperator, createMessage]);

	const handleCreateOperator = async () => {
		dispatch(createOperator(operatorData));
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className="modal"
			open={open}
			onClose={handleClose}
			closeAfterTransition
		>
			<Fade in={open}>
				<div className="paper">
					<h1 className="font-bold text-center">Add Operator</h1>
					<div className="my-4">
						<h3>Username</h3>
						<Input
							fullWidth
							placeholder="Operator username"
							name="username"
							onChange={handleOnChange}
						/>
					</div>
					<div className="my-4">
						<h3>Email</h3>
						<Input
							fullWidth
							placeholder="Operator Email"
							name="email"
							onChange={handleOnChange}
						/>
					</div>
					<div className="my-4">
						<h3>Password</h3>
						<Input
							fullWidth
							placeholder="Operator Password"
							name="password"
							onChange={handleOnChange}
							type={showPass ? "text" : "password"}
							endAdornment={
								<button onClick={() => setShowPass((prev) => !prev)}>
									<RemoveRedEyeOutlined />
								</button>
							}
						/>
					</div>
					<div className="flex justify-between my-6">
						<Button
							size="small"
							className="cancel-buttons"
							variant="contained"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							size="small"
							className="create-button"
							variant="contained"
							onClick={handleCreateOperator}
							disabled={creatingOperator}
						>
							{creatingOperator ? "Creating..." : "Save"}
						</Button>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default AddOperator;
