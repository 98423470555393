import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  loading: false,
  permission: null,
  permissions: [],
};

export default function getPermissions(state = initialState, action) {
  switch (action.type) {
    case types.PERMISSIONS_DATA_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PERMISSIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        permissions: action.permissions,
      };

    case types.PERMISSIONS_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
