import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCheck,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { authUserData, updateUserData } from "../../../redux/actions/auth/auth";

const ProfilePage = ({ onClose }) => {
  const [user, setUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const dispatch = useDispatch();
  const { user: profileData } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log("--working here--");
    const fetchData = async () => {
      await dispatch(authUserData());
      setUser(profileData);
    };

    fetchData();
  }, []); //empty array to make effect run only once

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [id]: value,
    }));
  };
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleUpdate = async () => {
    // console.log(user,"user is here");
    await dispatch(updateUserData(user));

    dispatch(authUserData());

    setIsEditing(false);
  };

  const handleAvatarSelection = (avatar) => {
    dispatch(updateUserData(avatar));
    setUser((state) => ({ ...state, image: avatar }));
    setShowAvatarModal(false);
  };

  // Placeholder avatar images
  const avatarImages = [
    "https://cdn5.vectorstock.com/i/1000x1000/72/74/female-avatar-profile-icon-round-woman-face-vector-18307274.jpg",
    "https://cdn5.vectorstock.com/i/1000x1000/01/69/businesswoman-character-avatar-icon-vector-12800169.jpg",
    "https://ptandler.github.io/vuepress-theme-single-page-sections/dummy-person.svg",
    "https://cdn3.iconfinder.com/data/icons/avatars-flat/33/man_5-1024.png",
  ];

  return (
    <Transition
      show={true} // values to control the visibility of the modal
      enter="transition ease-out duration-200"
      enterFrom="-translate-x-full"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="absolute top-20 z-50 right-5 shadow-custom rounded-lg  ">
        <div className="relative">
          <div className="bg-dark-grey h-12 rounded-t-lg" />
          <div className="bg-white rounded-b-lg p-5">
            <div className="relative">
              <div className="w-12 h-12 rounded-full overflow-hidden mx-auto ring-1 -mt-12 ring-white ">
                <img src={user?.image} alt="Profile" />
                {isEditing && (
                  <div className="absolute bottom-0 right-15 -mb-2 -mr-2">
                    <button
                      onClick={() => setShowAvatarModal(true)}
                      className="bg-gray-300 rounded-full h-6 w-6 p-1 ml-6 text-gray-500 hover:text-gray-700 hover:bg-gray-500 transition-colors"
                    >
                      <FontAwesomeIcon
                        icon={faCamera}
                        className="h-4 w-4 mb-1.5 "
                      />
                    </button>
                  </div>
                )}
              </div>

              <div className="absolute top-0 left-0 -right-3 py-2 px-4 flex justify-end">
                <button
                  onClick={onClose}
                  className="text-white hover:shadow-custom transition-colors -mt-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="mb-3">
              <h2 className="text-xl font-light text-custom-blue mb-2 font-custom">
                Personal Information
              </h2>
              <div className="flex flex-col">
                <div className="flex mb-4">
                  <label
                    htmlFor="firstName"
                    className="text-custom-blue text-sm mr-2 w-24 font-custom"
                  >
                    First Name
                  </label>
                  <div>
                    <input
                      type="text"
                      className={`form-input ${
                        isEditing ? "text-black" : "text-gray-500"
                      } focus:border-blue-700`}
                      id="firstName"
                      placeholder="First Name"
                      value={user?.firstName || ""}
                      style={{ fontSize: "0.9rem" }}
                      disabled={!isEditing}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex mb-4">
                  <label
                    htmlFor="lastName"
                    className="text-custom-blue text-sm mr-2 w-24"
                  >
                    Last Name
                  </label>
                  <div>
                    <input
                      type="text"
                      className={`form-input ${
                        isEditing ? "text-black" : "text-gray-500"
                      } focus:border-blue-700`}
                      id="lastName"
                      placeholder="Last Name"
                      value={user?.lastName || ""}
                      style={{ fontSize: "0.9rem" }}
                      disabled={!isEditing}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex mb-4">
                  <label
                    htmlFor="phoneNumber"
                    className="text-custom-blue text-sm font-normal mr-2 w-24"
                  >
                    Phone
                  </label>
                  <div>
                    <input
                      type="tel"
                      className={`form-input ${
                        isEditing ? "text-black" : "text-gray-500"
                      } focus:border-custom-blue`}
                      id="phoneNumber"
                      placeholder="Phone"
                      value={user?.phoneNumber || ""}
                      style={{ fontSize: "0.9rem" }}
                      disabled={!isEditing}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex mb-4">
                  <label
                    htmlFor="email"
                    className="text-custom-blue text-sm font-normal mr-2 w-24"
                  >
                    Email
                  </label>
                  <div>
                    <input
                      type="email"
                      className={`form-input ${
                        isEditing ? "text-black" : "text-gray-500"
                      } focus:border-blue-700`}
                      id="email"
                      placeholder="Email"
                      value={user?.email || ""}
                      style={{ fontSize: "0.9rem" }}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex mb-4">
                  <label
                    htmlFor="company"
                    className="text-custom-blue text-sm font-normal mr-2 w-24"
                  >
                    Company
                  </label>
                  <div>
                    <input
                      type="text"
                      className={`form-input ${
                        isEditing ? "text-black " : "text-gray-500 "
                      } focus:border-blue-700`}
                      id="company"
                      placeholder="Company"
                      value={user?.company?.name ?? "Unavailable"}
                      style={{ fontSize: "0.9rem" }}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isEditing && (
              <button
                type="submit"
                className="bg-white border border-gray-300 hover:border-blue-700 shadow-xl text-custom-blue font-bold py-2 px-2 rounded-lg flex items-center"
                onClick={handleEdit}
              >
                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                <span>Edit Details</span>
              </button>
            )}

            {isEditing && (
              <button
                type="submit"
                className="bg-custom-blue hover:bg-blue-500 text-white font-bold py-2 px-3 rounded-lg flex items-center"
                onClick={handleUpdate}
                disabled={isUpdating}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                <span>{isUpdating ? "Updating..." : "Save Changes"}</span>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Avatar Selection Modal */}
      {showAvatarModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-gray-100 p-2 rounded-lg shadow-custom">
            <h3 className="text-lg font-light text-custom-blue mb-4">
              Select an Avatar
            </h3>
            <div className="grid grid-cols-4 gap-4">
              {avatarImages.map((avatar, index) => (
                <div
                  key={index}
                  className="relative rounded-full cursor-pointer ring-transparent hover:ring-2 hover:ring-custom-blue transition duration-300 overflow-hidden avatar-option"
                  onClick={() => handleAvatarSelection(avatar)}
                >
                  <img
                    src={avatar}
                    alt={`Avatar ${index + 1}`}
                    className="w-16 h-16 object-cover"
                  />
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowAvatarModal(false)}
              className="bg-custom-blue hover:bg-blue-300 rounded px-3 py-1 mt-4"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default ProfilePage;
