import { formatter } from "./currencyFormatter";

export const sanitizeUssdTransactions = (data) => {
  return data.map((item, index) => ({
    id: index + 1,
    createdTime: item.createdAt,
    paymentTime: item.paymentTime,
    cardNumber: item.cardNumber,
    phoneNumber: item.phoneNumber,
    oldBalance: item.oldBalance,
    amount: item.amount,
    promotion: item.promotion || "_",
    newBalance: item.newBalance,
    momoStatus: item.momoStatus,
    status: item.txStatus,
  }));
};

export const statisticFormatter = (data) => {
  const success = data?.filter((item) => item.txStatus === "SUCCESSFUL").length;

  const failed = data?.filter((item) => item.txStatus === "FAILED").length;

  const amount = data
    ?.filter((item) => item.txStatus === "SUCCESSFUL")
    .reduce((prev, curr) => {
      return prev + Number(curr.amount);
    }, 0);

  return {
    success,
    failed,
    amount: formatter.format(amount),
  };
};
