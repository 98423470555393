import http from "../../../utils/axios/axios";
import { types } from "../types";

export const getTransactions = (date) => (dispatch) => {
	dispatch({ type: types.TRANSACTIONS_PENDING });
	return http
		.post(`/api/transactions`, { date })
		.then((res) => {
			dispatch({
				type: types.GET_TRANSACTIONS_SUCCESS,
				message: res.data.message,
				transactions: res.data.data,
			});
		})
		.catch((err) => {
			dispatch({
				type: types.TRANSACTIONS_ERROR,
				error: err.response ? err.response.data.error : "Error occured",
			});
		});
};

export const getUssdTransactions =
	(filters = {}) =>
	async (dispatch) => {
		dispatch({ type: types.USSD_TRANSACTIONS_PENDING });
		try {
			const data = await fetch(
				`${process.env.REACT_APP_USSD_URL}/transactions`,
				{
					headers: {
						"Content-Type": "application/json",
					},
					method: "POST",
					body: JSON.stringify(filters),
				}
			);
			const res = await data.json();
			dispatch({
				type: types.GET_USSD_TRANSACTIONS_SUCCESS,
				message: res.message,
				data: res.transactions,
			});
		} catch (err) {
			dispatch({
				type: types.USSD_TRANSACTIONS_ERROR,
				error: err.response ? err.response.data.error : "Error occured",
			});
		}
	};

export const getSingleUssdTransaction = (transactionId) => async (dispatch) => {
	dispatch({ type: types.SINGLE_USSD_TRANSACTION_PENDING });
	try {
		const data = await fetch(`${process.env.REACT_APP_USSD_URL}/transaction`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			body: JSON.stringify({ transactionId }),
		});
		const res = await data.json();
		dispatch({
			type: types.GET_SINGLE_USSD_TRANSACTION_SUCCESS,
			message: res.message,
			data: res.transaction,
		});
	} catch (err) {
		dispatch({
			type: types.SINGLE_USSD_TRANSACTION_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const getCokTopupTransaction = () => async (dispatch) => {
	dispatch({ type: types.COK_TOPUP_TRANSACTIONS_PENDING });
	try {
		const data = await fetch(
			`${process.env.REACT_APP_KIGALI_TOPUP_URL}/transactions`,
			{
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({}),
			}
		);
		const res = await data.json();
		dispatch({
			type: types.COK_TOPUP_TRANSACTIONS_SUCCESS,
			message: res.message,
			data: res.data,
		});
	} catch (err) {
		dispatch({
			type: types.COK_TOPUP_TRANSACTIONS_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const getPromotions = () => async (dispatch) => {
	dispatch({ type: types.USSD_PROMOTIONS_PENDING });
	try {
		const data = await fetch(`${process.env.REACT_APP_USSD_URL}/promotions`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
		});
		const res = await data.json();
		dispatch({
			type: types.GET_USSD_PROMOTIONS_SUCCESS,
			message: res.message,
			data: res.promotions,
		});
	} catch (err) {
		dispatch({
			type: types.USSD_PROMOTIONS_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const savePromotion = (payload) => async (dispatch) => {
	dispatch({ type: types.CREATE_PROMOTION_PENDING });
	try {
		const response = await fetch(
			`${process.env.REACT_APP_USSD_URL}/promotions/create`,
			{
				headers: {
					"Content-Type": "application/JSON",
				},
				method: "POST",
				body: JSON.stringify(payload),
			}
		);
		const res = await response.json();
		dispatch({
			type: types.CREATE_PROMOTION_SUCCESS,
			message: res.message,
		});
	} catch (err) {
		dispatch({
			type: types.CREATE_PROMOTION_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const forceTransaction = (payload) => async (dispatch) => {
	dispatch({ type: types.FORCE_TRANSACTION_PENDING });
	try {
		const response = await fetch(
			`${process.env.REACT_APP_USSD_URL}/transactions/force`,
			{
				headers: {
					"Content-Type": "application/JSON",
				},
				method: "POST",
				body: JSON.stringify(payload),
			}
		);
		const res = await response.json();
		dispatch({
			type: types.FORCE_TRANSACTION_SUCCESS,
			message: res.message,
		});
	} catch (err) {
		dispatch({
			type: types.FORCE_TRANSACTION_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const activatePromotion = (payload) => async (dispatch) => {
	dispatch({ type: types.ACTIVATE_PROMOTION_PENDING });
	try {
		const response = await fetch(
			`${process.env.REACT_APP_USSD_URL}/promotions/activate`,
			{
				headers: {
					"Content-Type": "application/JSON",
				},
				method: "POST",
				body: JSON.stringify(payload),
			}
		);
		const res = await response.json();
		dispatch({
			type: types.ACTIVATE_PROMOTION_SUCCESS,
			message: res.message,
		});
	} catch (err) {
		dispatch({
			type: types.ACTIVATE_PROMOTION_ERROR,
			error: err.response ? err.response.data.error : "Error occured",
		});
	}
};

export const getDetailedData =
	(filters = {}, actionType) =>
	async (dispatch) => {
		try {
			const data = await fetch(
				`${process.env.REACT_APP_USSD_URL}/transactions`,
				{
					headers: {
						"Content-Type": "application/json",
					},
					method: "POST",
					body: JSON.stringify(filters),
				}
			);
			const res = await data.json();
			dispatch({
				type: actionType,
				message: res.message,
				data: res.transactions,
			});
		} catch (err) {
			// dispatch({
			//   type: types.USSD_TRANSACTIONS_ERROR,
			//   error: err.response ? err.response.data.error : "Error occured",
			// });
		}
	};
