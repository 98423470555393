import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import {
  getUssdTransactions,
  getDetailedData,
  getSingleUssdTransaction,
} from "../../redux/actions/transactions/transactions";
import { types } from "../../redux/actions/types";
import { formatter } from "../../utils/currencyFormatter";
import TransactionDetails from "../../components/TransactionDetails";
import { useNavigate } from "react-router-dom";
import {
  getStartOfMonth,
  getStartOfWeek,
  getTodayISO,
  getUtcDate,
} from "../../utils/getISOToday";
import { utils, writeFileXLSX } from "xlsx";
import {
  sanitizeUssdTransactions,
  statisticFormatter,
} from "../../utils/sanitizeData";

const UssdTransactions = () => {
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const [dateValue, setDateValue] = useState();
  const state = useSelector((state) => state.momoTransactions);
  const navigate = useNavigate();

  let rows = [];
  useEffect(() => {
    const filters = dateValue
      ? {
          startTime: getUtcDate(dateValue.startDate),
          endTime: getUtcDate(dateValue.endDate, true),
          applyFilters: true,
        }
      : {
          startTime: getUtcDate(getStartOfMonth()),
          endTime: getUtcDate(getTodayISO(), true),
          applyFilters: true,
        };
    dispatch(getUssdTransactions(filters));
  }, [dispatch, dateValue]);

  useEffect(() => {
    const todayFilter = {
      startTime: getUtcDate(getTodayISO()),
      endTime: getUtcDate(getTodayISO(), true),
      applyFilters: true,
    };
    const weekFilter = {
      startTime: getUtcDate(getStartOfWeek()),
      endTime: getUtcDate(getTodayISO(), true),
      applyFilters: true,
    };
    const monthfilter = {
      startTime: getUtcDate(getStartOfMonth()),
      endTime: getUtcDate(getTodayISO(), true),
      applyFilters: true,
    };
    dispatch(getDetailedData(todayFilter, types.GET_TODAY_DATA));
    dispatch(getDetailedData(weekFilter, types.GET_WEEK_DATA));
    dispatch(getDetailedData(monthfilter, types.GET_MONTH_DATA));
  }, [dispatch]);

  let total = 0;
  if (state && state.ussdTransactions) {
    rows = state.ussdTransactions;
    total = state.ussdTransactions
      .filter((item) => item.txStatus === "SUCCESSFUL")
      .reduce((prev, curr) => {
        return prev + Number(curr.amount);
      }, 0);
  }

  const ussdStatistics = [
    {
      title: "Today",
      ...statisticFormatter(state?.todayData),
    },
    {
      title: "This week",
      ...statisticFormatter(state?.weekData),
    },
    {
      title: "This month",
      ...statisticFormatter(state?.monthData),
    },
  ];

  const getTransactionDetails = (id) => {
    dispatch(getSingleUssdTransaction(id));
    setOpenDetails(true);
  };

  const exportData = (data) => {
    const sheet = sanitizeUssdTransactions(data);
    const ws = utils.json_to_sheet(sheet);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    const fileName = `ussd_transactions_reports_${
      dateValue?.startDate || getTodayISO()
    }_to_${dateValue?.endDate || getTodayISO()}.xlsx`;
    writeFileXLSX(wb, fileName);
  };

  const columns = [
    "No",
    "Date",
    "Momo Ref",
    "Card Number",
    "PhoneNumber",
    "Promotion in %",
    "Promotion Amount",
    "Old Balance Rwf",
    "Loaded Amount",
    "Balance Rwf",
    "Status",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <CustomTable
            rows={rows}
            columns={columns}
            total={formatter.format(total)}
            tableType="ussd"
            setDateValue={setDateValue}
            dateValue={dateValue}
            action={() => navigate("/dashboard/promotions")}
            viewMore={getTransactionDetails}
            useMultipleDates
            exportData={exportData}
            statistics={ussdStatistics}
          />
        </>
      )}
      <TransactionDetails
        open={openDetails}
        handleClose={() => setOpenDetails(false)}
        details={state.singleUssdTransaction}
        loading={state.loadingSingleUssdTransaction}
      />
    </div>
  );
};

export default UssdTransactions;
