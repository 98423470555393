import { types } from "../../actions/types";

const initialState = {
    message: null,
    error: null,
    loading: null,
    cardCount: 0,
    profileCount: 0,
    cards: [],
    profiles: []
}

export default function cards(state = initialState, action) {
    switch (action.type) {
        case types.GET_CARDS_PENDING:
            return {
                ...state,
                loading: true
            }
        case types.GET_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: action.message,
                cards: action.data,
                cardCount: action.count
            }
        case types.GET_CARDS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                message: action.message,
                profiles: action.data,
                profileCount: action.count
            }
        default:
            return state;
    }
}