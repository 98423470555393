import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/CustomTable";
import Loader from "../../components/Loader";
import { getTransactions } from "../../redux/actions/transactions/transactions";
import { formatter } from "../../utils/currencyFormatter";
import { getTodayISO } from "../../utils/getISOToday";

const MomoTransactions = () => {
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState();
  const state = useSelector((state) => state.momoTransactions);

  useEffect(() => {
    dispatch(getTransactions(dateValue || getTodayISO()));
  }, [dispatch, dateValue]);
  let rows = [];
  let total = 0;
  if (state && state.transactions && state.transactions.transactions) {
    total = state.transactions.total;
    rows = state.transactions.transactions;
  }
  const columns = [
    "No",
    "External Txn ID",
    "Internal Txn ID",
    "Payer",
    "Amount",
    "Status",
    "Company",
    "Date",
  ];
  return (
    <div>
      {state && state.loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <CustomTable
            rows={rows}
            columns={columns}
            total={formatter.format(total)}
            tableType="momo"
            setDateValue={setDateValue}
            dateValue={dateValue}
          />
        </>
      )}
    </div>
  );
};

export default MomoTransactions;
