import { Route, Routes } from "react-router-dom";
import Login from "./screens/auth/Login";
import MomoTransactions from "./screens/momo/MomoTransactions";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Admin from "./screens/admin/Admin";
import Taps from "./screens/taps/Taps";
import Dashboard from "./components/dashboard/Dashboard";
import Users from "./components/dashboard/users/Users";
import Overview from "./components/dashboard/Overview";
import Companies from "./components/dashboard/companies/Companies";
import Roles from "./components/dashboard/roles/Roles";
import Permissions from "./components/dashboard/permissions/Permissions";
import ProfilePage from "./screens/profile/profilepage/ProfilePage";
import UssdTransactions from "./screens/ussd/UssdTransaction";
import Promotions from "./screens/promotions/Promotions";
import Profile from "./screens/Profiles/Profile";
import Cards from "./screens/cards/Cards";
import CokTopupTransactions from "./screens/cokTopup/cokTransactions";
import CokOperators from "./screens/cokOperators/cokOperators";

library.add(fab);

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Login />} />
				{/* <Route path="/about" element={<About />} /> */}
				<Route path="/ProfilePage" element={<ProfilePage />} />
				<Route path="admin" element={<Admin />} />
				<Route path="dashboard" element={<Dashboard />}>
					<Route path="" element={<Overview />} />
					<Route
						path="users"
						element={
							<div style={{ width: "100%" }}>
								<Users />
							</div>
						}
					/>
					<Route
						path="companies"
						element={
							<div style={{ width: "100%" }}>
								<Companies />
							</div>
						}
					/>
					<Route
						path="users/permissions"
						element={
							<div style={{ width: "100%" }}>
								<Permissions />
							</div>
						}
					/>
					<Route
						path="users/roles"
						element={
							<div style={{ width: "100%" }}>
								<Roles />
							</div>
						}
					/>
					<Route path="wifi-usage" element={<Taps />} />
					<Route path="momo" element={<MomoTransactions />} />
					<Route path="ussd" element={<UssdTransactions />} />
					<Route path="cok-topup" element={<CokTopupTransactions />} />
					<Route path="cok-operators" element={<CokOperators />} />
					<Route path="cards-profiles" element={<Profile />} />
					<Route path="cards" element={<Cards />} />
					<Route path="promotions" element={<Promotions />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
