import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  loading: false,
  user: null,
  users: null,
};

export default function getUsers(state = initialState, action) {
  switch (action.type) {
    case types.USERS_DATA_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        users: action.users,
      };

    case types.USERS_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
