import { types } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
  loading: false,
  role: null,
  roles: null,
};

export default function getRoles(state = initialState, action) {
  switch (action.type) {
    case types.ROLES_DATA_PENDING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ROLES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        roles: action.roles,
      };

    case types.ROLES_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
