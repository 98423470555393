import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader";
import { getPermissions } from "../../../redux/actions/permissions/Permissions";
import CustomTable from "../../CustomTable";

const Permissions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.permissions);
  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);
  useEffect(() => {}, [state]);
  let rows = [];

  if (state && state.permissions) {
    rows = state.permissions;
  }
  const columns = ["No", "Permission", "Status", "Description", "Actions"];
  return (
    <div>
      {state && state.loading ? (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <Loader />
        </>
      ) : (
        <>
          <div style={{ position: "sticky", top: "0", zIndex: "1" }}></div>
          <CustomTable
            rows={rows}
            columns={columns}
            tableType="permissions"
            tableTitle="All permissions"
          />
        </>
      )}
    </div>
  );
};

export default Permissions;
